<template>
  <router-view>
  </router-view>
</template>
<script setup>

import {watch} from "vue";

const debounce = (fn, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}

import {useDeviceDetect} from "@/utils/useDeviceDetect";
const { isMobile } = useDeviceDetect()
watch(() => isMobile.value , () => {
  console.log('isMobile',isMobile.value)
  if (isMobile.value === false) {
    document.body.classList.add('desktop')
    document.body.classList.remove('mobile')
  } else {
    document.body.classList.add('mobile')
    document.body.classList.remove('desktop')
  }
}, {
  immediate: true
})

</script>

<style lang="scss">
</style>

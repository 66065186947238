// composables/useDeviceDetect.js
import { ref, onMounted } from 'vue'

export function useDeviceDetect() {
    const isMobile = ref(false)

    const checkDevice = () => {
        const userAgent = navigator.userAgent.toLowerCase()
        const mobileKeywords = [
            'mobile',
            'android',
            'iphone',
            'ipad',
            'windows phone',
            'ipod',
            'blackberry'
        ]

        isMobile.value = mobileKeywords.some(keyword => userAgent.includes(keyword))
    }

    // 检查屏幕宽度
    const checkScreenWidth = () => {
        isMobile.value = window.innerWidth <= 880 // 可以根据需要调整临界值
        console.log('检测屏幕宽度', isMobile.value)
    }

    onMounted(() => {
        checkDevice()
        // 监听窗口大小变化
        window.addEventListener('resize', checkScreenWidth)
    })

    return {
        isMobile
    }
}

import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
const routes = [
  {
    path: '/',
    redirect: '/chat/message'
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/chat',
    component: () => import('@/views/pc/layout.vue'),
    children: [
      {
        name: 'message',
        path: '/chat/message/:id?/:name?',
        component: () => import('@/views/pc/message.vue')
      },
      {
        name: 'history',
        path: '/chat/history',
        component: () => import('@/views/pc/history.vue')
      }
    ]
  },
  {
    path: '/mobile/chat/message',
    component: () => import('@/views/mobile/chat.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    const token = store.getters.token
    if (!token) {
      // 创建token 设置token
      let _token = (Math.random() * 100000000000000000000).toString(36).substring(5, 16) + '' + new Date().getTime()
      store.commit('setToken', _token)
    }
    next()
})
export default router

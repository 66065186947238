import request from '@/utils/useRequest'
export const http = {
    login: (data) => {
        return request({
            url: '/user/login',
            data,
            method: 'post'
        })
    },
    // 注册
    register: (data) => {
        return request({
            url: '/user/register',
            data,
            method: 'post'
        })
    },
    // 保存消息
    saveMessage: (data) => {
        return request({
            url: '/message',
            data: {
                ...data,
                platform: 'client'
            },
            method: 'post'
        })
    },
    // 创建用户
    createUser: (data) => {
        return request({
            url: '/member',
            data: {
                ...data,
                platform: 'client'
            },
            method: 'post'
        })
    },
    // 创建商品日志
    createProductLog: (data) => {
        return request({
            url: '/messageProductLog',
            data: {
                ...data,
                platform: 'client'
            },
            method: 'post'
        })
    }
}
